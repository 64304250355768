import React from 'react';
import globalHook from 'use-global-hook';

import { actions, associatedActions } from './actions'
import { appGlobalState } from './interface'

const initialState: appGlobalState = {
  mainBackgroundImageUrl: "",
  unsplashSourceUrl: "https://source.unsplash.com/random",
  drawerOpen: false,
  loading: false
};

const useGlobal = globalHook<appGlobalState, associatedActions>(
  React,
  initialState,
  actions
);

export default useGlobal;

