import React, { CSSProperties, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';

import { useHistory } from 'react-router-dom'

import './App.css';

import Routes from "./routes";
import MainListItems from './menuItems';

import useStyles from './theme'

import useGlobal from './store/store'
import { CircularProgress } from '@material-ui/core';

const App: React.FC = () => {

    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const [open,] = React.useState(false)

    const [globalState, globalActions] = useGlobal()

    const appStyle: CSSProperties = {
      display: "flex",
      margin: 0,
      height: "100vh",
      width: "100wh",
      textAlign: "center",
      opacity: 0.9,
      backgroundImage: 'url(' + globalState.mainBackgroundImageUrl + ')',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      overflowX: "auto"
    }

    function handleDrawerOpen() {
        globalActions.setDrawerOpen(true)
    }

    function handleDrawerClose() {
        globalActions.setDrawerOpen(false)
    }

    useEffect(() => {
    
        globalActions.setLoading(true)

        fetch(globalState.unsplashSourceUrl)
          .then(response => { 
              console.log("Background:", response.url);
              globalActions.setMainBackgroundImageUrl( response.url)
              globalActions.setLoading(false)
        }).catch(error => {
              console.log("Background: default")
              globalActions.setLoading(false)
        });
    
      },[globalActions, globalState.unsplashSourceUrl])
    

    return (

        <div style={appStyle}>

        <div className={classes.root}>

            <CssBaseline />

            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
 
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={{width: "100%", textAlign:"left"}}>
                    <Button variant="text" style={{color:"white"}} onClick={() => history.push("/")}> 
                        <Typography style={{width:"100%", textAlign:"left"}} variant="h6" noWrap>
                            Happiness Engineering
                        </Typography>
                    </Button>
                    </div>
                    <div className={classes.barRight}>
                        {globalState.loading ? <CircularProgress size={15} style={{color:"white"}}/> : null }
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                // variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: globalState.drawerOpen,
                    [classes.drawerClose]: !globalState.drawerOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: globalState.drawerOpen,
                        [classes.drawerClose]: !globalState.drawerOpen,
                    }),
                }}
                open={globalState.drawerOpen}
                onClose={handleDrawerClose}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />

                <MainListItems />

            </Drawer>

            <main className={classes.content}>
                <div className={classes.toolbar} />
                    <Routes />
            </main>
        </div>
        </div>

  );

}

export default App;

