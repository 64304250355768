import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import MailIcon from '@material-ui/icons/MailOutlined'
import RefreshIcon from '@material-ui/icons/RefreshOutlined'
import BuildIcon from '@material-ui/icons/BuildOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useHistory } from 'react-router-dom'

import useGlobal from './store/store'

const MainListItems: React.FC = () => {
    
    const history = useHistory()
    
    const [globalState, globalActions] = useGlobal()

    const handleMenuItemClick = (page: string) => {

        history.push(page)
        globalActions.setDrawerOpen(false)

    }

    const refreshMedia = () => {

        globalActions.setLoading(true)

        fetch(globalState.unsplashSourceUrl)
            .then(response => { 
                console.log("Background:", response.url); 
                globalActions.setMainBackgroundImageUrl( response.url)
                globalActions.setDrawerOpen(false)
                globalActions.setLoading(false)
        });

    }
    
    return (

        <div>
            <List>
                <ListItem
                    button
                    onClick={() => handleMenuItemClick("/")}
                >
                    <ListItemIcon style={{marginLeft:7}}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>

                <ListItem
                    button
                    onClick={() => handleMenuItemClick("/cv")}
                >
                    <ListItemIcon style={{marginLeft:7}}>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary="CV" />
                </ListItem>

                <ListItem
                    button
                    onClick={() => handleMenuItemClick("/projects")}
                >
                    <ListItemIcon style={{marginLeft:7}}>
                        <BuildIcon />
                    </ListItemIcon>
                    <ListItemText primary="Projects" />
                </ListItem>

                <ListItem
                    button
                    onClick={() => handleMenuItemClick("/contact")}
                >
                    <ListItemIcon style={{marginLeft:7}}>
                        <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contact" />
                </ListItem>

                <ListItem
                    button
                    onClick={() => refreshMedia()}
                >
                    <ListItemIcon style={{marginLeft:7}}>
                        {globalState.loading ? <CircularProgress /> : <RefreshIcon />}
                    </ListItemIcon>
                    <ListItemText primary="Refresh" />
                </ListItem>

            </List>

        </div>
    )

}

export default MainListItems;

